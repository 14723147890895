import { createApp, h } from 'vue'
// import bootstrapVue3 from './plugins/bootstrap-vue'
import {BootstrapVue3} from 'bootstrap-vue-3';
import App from './App.vue'

// Import Theme scss
//import './assets/scss/app.scss'
import '../node_modules/nprogress/nprogress.css' 
import "./assets/scss/auth.scss";
import "./assets/scss/tailwind.scss";

//bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
//jquery
window.$ = window.jQuery = require('jquery');
//Toast
import Notifications from '@kyvg/vue3-notification'
//nprogress
//nprogress
import Progress from 'nprogress';
Progress.configure({
  minimum: 0.08, //0.08
  easing: 'linear', //ease linear
  positionUsing: '',
  speed: 200, //200
  trickle: true,
  trickleSpeed: 200,
  // showSpinner: true,
  barSelector: '[role="bar"]',
  spinnerSelector: '[role="spinner"]',
  // parent: 'body', //body #spinner
  template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
});
import underscore from 'underscore';

//plugings plantilla
import {createPinia} from 'pinia'
//------------------------

const moment = require('moment');
require('moment/locale/es');


import VeeValidation from '@/class/vee-validation.class';
//vee-validate
import { defineRule ,configure  } from 'vee-validate';
configure(VeeValidation.getClass());
//defineRule('positive', VeeValidation.getPositive());
//defineRule('required', VeeValidation.getRequired());
defineRule('required', value => {
    if (!value || !value.length) {
      return 'This field is required';
    }
    return true;
  });
//defineRule('requiredTypeHead', VeeValidation.requiredTypeHead());
//defineRule('min', VeeValidation.getMin());
//defineRule('max', VeeValidation.getMax());
//defineRule('email', VeeValidation.getEmail());
//defineRule('confirmed', VeeValidation.getConfirmed());


//interceptor
import interceptor from '@/config/interceptor';

//router
import router from './routes/index';

const app = createApp({
    render: () => h(App)
})

//CONST PLANTILLA
const pinia = createPinia();
app.use(pinia)
//------------------

app.use(router)
app.use(BootstrapVue3)
app.use(Progress);
app.use(underscore)
app.use(Notifications, { group: 'homee' })
app.config.globalProperties.$http = interceptor
app.config.globalProperties.$store = {};
app.use(moment)

app.mount('#app')

//Platilla
import {useThemeSettingsStore} from "@/plantilla/store/themeSettings";
const themeSettingsStore = useThemeSettingsStore()

// check localStorage theme for dark light bordered
if (localStorage.theme === "dark") {
    document.body.classList.add("dark");
    themeSettingsStore.theme = "dark";
    themeSettingsStore.isDark = true;
} else {
    document.body.classList.add("light");
    themeSettingsStore.theme = "light";
    themeSettingsStore.isDark = false;
}
if (localStorage.semiDark === "true") {
    document.body.classList.add("semi-dark");
    themeSettingsStore.semidark = true;
    themeSettingsStore.semiDarkTheme = "semi-dark";
} else {
    document.body.classList.add("semi-light");
    themeSettingsStore.semidark = false;
    themeSettingsStore.semiDarkTheme = "semi-light";
}
// check loacl storege for menuLayout
if (localStorage.menuLayout === "horizontal") {
    themeSettingsStore.menuLayout = "horizontal";
} else {
    themeSettingsStore.menuLayout = "vertical";
}

// check skin  for localstorage
if (localStorage.skin === "bordered") {
    themeSettingsStore.skin = "bordered";
    document.body.classList.add("skin--bordered");
} else {
    themeSettingsStore.skin = "default";
    document.body.classList.add("skin--default");
}
// check direction for localstorage
if (localStorage.direction === "true") {
    themeSettingsStore.direction = true;
    document.documentElement.setAttribute("dir", "rtl");
} else {
    themeSettingsStore.direction = false;
    document.documentElement.setAttribute("dir", "ltr");
}

// Check if the monochrome mode is set or not
if (localStorage.getItem('monochrome') !== null) {
    themeSettingsStore.monochrome = true;
    document.getElementsByTagName( 'html' )[0].classList.add('grayscale');
}
