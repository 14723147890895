<template>
    <div class="bg-white">        
        <div class="card-header pb-0">
            <div class="row">
                <div class="col-lg-4">
                    <h5>{{title}}</h5>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form autocomplete="off" class="pace-y-4">
                <div class="row">                   
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Nombre</label>
                             <div class="col-sm-9">
                                <b-form-input id="nombre" class="form-control form-control-sm" v-model="item.nombre" v-on:keydown="validItemForm('nombre')" v-on:keyup="validItemForm('nombre')"></b-form-input>
                            </div>
                        </div>                        

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Fecha Fin</label>
                             <div class="col-sm-9">                                
                                <b-form-input id="fechaFin" type="date" class="form-control form-control-sm" v-model="item.fechaFin" v-on:keydown="validItemForm('fechaFin')" v-on:keyup="validItemForm('fechaFin')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Fecha Limite de destrucción de soca</label>
                             <div class="col-sm-9">
                                <b-form-input id="fechaLimiteDestruccionDeSoca" type="date" class="form-control form-control-sm" v-model="item.fechaLimiteDestruccionDeSoca" v-on:keydown="validItemForm('fechaLimiteDestruccionDeSoca')" v-on:keyup="validItemForm('fechaLimiteDestruccionDeSoca')"></b-form-input>
                            </div>
                        </div>                                          
                        
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Fecha Inicio</label>
                             <div class="col-sm-9">
                                <b-form-input id="fechaInicio" type="date" class="form-control form-control-sm" v-model="item.fechaInicio" v-on:keydown="validItemForm('fechaInicio')" v-on:keyup="validItemForm('fechaInicio')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Tipo de ciclo</label>
                             <div class="col-sm-9">
                                <select id="tipoCicloAgricolaId" class="form-control form-control-sm" v-model="item.tipoCicloAgricolaId" v-on:change="validItemForm('tipoCicloAgricolaId')">
                                    <option v-for="itemT in tipoCicloAgricolas" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>  

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Estatus</label>
                             <div class="col-sm-9">
                                <select id="estatusId" class="form-control form-control-sm" v-model="item.estatusId" v-on:change="validItemForm('estatusId')">
                                    <option v-for="itemT in estatus" v-bind:value="itemT.id" >{{itemT.text}}</option>
                                </select>
                            </div>
                        </div>     

                    </div>
                </div>  
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-6 offset-lg-6 offset-xl-6">
                        <div class="form-group row pb-3 text-right"> 
                            <div class="col-12">
                                <router-link to="/catalogo/cicloAgricola" class="text-white">
                                    <button class="btn btn-danger">
                                        <feather type="arrow-left" style="height: 18px; padding-top: 2px; color:white;"></feather>Regresar
                                    </button>
                                </router-link>
                                <!-- <Button text="Aceptar" btnClass="btn-dark" @click="save"/> -->
                                <button type="button" @click="save" class="ml-1 btn btn-dark"><feather type="save" style="height: 18px; padding-top: 2px;"></feather> Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>              
            </form>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import cicloAgricolaService             from "@/services/cicloAgricola.service";
    import tipoCicloAgricolaService         from "@/services/tipoCicloAgricola.service";
    import _                                from 'underscore';    
    import Toast                            from '@/class/toast.class';
    import FormValidation                   from '@/class/form-validation.class';
    import GlobalMessages                   from '@/const/messages.const';
    import utilitiesService                 from '@/services/utilities.service';
    import Button                           from '@/components/plantilla/Button';
    import moment                           from 'moment';

    export default{
        name: 'form-cicloAgricola',
        data(){
            return {
                title                   : 'Nuevo Ciclo Agricola',
                id                      : 0,
                item                    : {},
                estatus                 : [ {id: 1, text: 'ACTIVO'}, { id: 2, text: 'INACTIVO' } ],
                tipoCicloAgricolas      : [],
                formValidation          : null
            }
        },
        methods:{           
            getById(){
                cicloAgricolaService.getById(this.id).then(res => {
                    this.item = res;
                    setTimeout(function(){
                        $('#fechaInicio').val(moment(res.fechaInicio).format('YYYY-MM-DD'));
                        $('#fechaFin').val(moment(res.fechaFin).format('YYYY-MM-DD'))
                        $('#fechaLimiteDestruccionDeSoca').val(moment(res.fechaLimiteDestruccionDeSoca).format('YYYY-MM-DD'))
                    }, 300);
                    
                }).catch(error => {
                    console.log(error)
                });
            },         
            getDataSelecteds(){
                tipoCicloAgricolaService.get().then(res => {
                    this.tipoCicloAgricolas = res;
                }).catch(error => {
                    Toast.danger(error.message);
                });
            },
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.item[id]);
            },
            save(){
                if(!this.formValidation.isValid(this.item)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }
                cicloAgricolaService[this.id != 0 ? 'update' : 'create'](this.item).then(res => {                        
                    Toast.success(`Ciclo Agricola ${this.id != '' ? 'actualizado' : 'creado'} exitosamente`);
                    this.$router.push('/catalogo/cicloAgricola');
                }).catch(err => {
                    Toast.danger(this.id != 0 ? GlobalMessages.ERROR_UPDATED_DATA : GlobalMessages.ERROR_CREATE_DATA);
                });            
            }
        },
        mounted(){
            let arrayControls = [
                { id: 'nombre',                             validators:{ required: true, maxLength: 45 }, default: '' },
                { id: 'fechaInicio',                        validators:{ required: true, maxLength: 45 }, default: '' },
                { id: 'fechaFin',                           validators:{ required: true, maxLength: 45 }, default: '' },
                { id: 'tipoCicloAgricolaId',                validators:{ required: true }, default: 0 },
                { id: 'fechaLimiteDestruccionDeSoca',       validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'estatusId',                          validators:{ required: true }, default: 1 },
            ];

            if(this.$route.params && this.$route.params.id){
                this.title  = 'Editar Ciclo Agricola';
                this.id     = this.$route.params.id;
                this.getById();
            }

            this.formValidation = new FormValidation(arrayControls);
            this.item           = this.formValidation.createStructure();
            this.getDataSelecteds();
        },
        components:{
            Button
        }
    }
</script>