export default class VeeValidation{
    static getClass(){
        return {
            classes: {
                valid: 'is-valid',
                invalid: 'is-invalid',
                dirty: ['is-dirty', 'is-dirty'],
            }
        }
    }

    static getRequired(){
        return {
            validate(value) {
                return {
                    required: true,
                    valid: [0, '', null, undefined].indexOf(value) === -1
                };
            },
            computesRequired: true,        
            message: 'El campo {_field_} es requerido.'
        }
    }

    static requiredTypeHead(){
        return {
            validate(value, { target }) {
                if([0, '', null, undefined].indexOf(value) !== -1){
                    return true;
                }
                return [0, '', null, undefined].indexOf(target) === -1;
            }, 
            params: ['target'],      
            message: 'El campo {_field_} es requerido.'
        }
    }

    static getPositive(){
        return {
            validate(value) {
                return value >= 0;                
            },
            message: 'El campo {_field_} debe de ser numero positivo'        
        }
    }

    static getMin(){
        return {
            validate(value, {length}) {
                return value != undefined ? value.length >= length : true;                
            },
            params: ['length'],
            message: 'El campo {_field_} debe de tener al menos {length} caracteres.'
        }
    }

    static getMax(){
        return {
            validate(value, {length}) {
                return value != undefined ? value.length < length : true;                
            },
            params: ['length'],
            message: 'El campo {_field_} debe de tener maximo {length} caracteres.'
        }
    }

    static getEmail(){
        return {
            validate(value) {
                //let regexEmail = /^\w+([ \ .-]?\w+)*@\w+([ \ .-]?\w+)*(\.\w{2,3})+$/; se quito 2 barras invertidas
                let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return regexEmail.test(value);              
            },
            message: 'El campo {_field_} no tiene el formato valido.'
        }
    }

    static getConfirmed(){
        return {
            params: ['target'],
            validate(value, { target }) {
              return value === target;
            },
            message: 'La confirmación de la contraseña no coincide.'
          }
    }
}