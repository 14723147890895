export const menuItems = [
  {
    title: "Catalógos",
    icon: "heroicons-outline:bars-4",
    link: "#",
    child: [
      {
        childtitle: "Dependencias",
        childlink: "/catalogo/dependencias",
      },
      {
        childtitle: "Tipo Dependencia",
        childlink: "/catalogo/tipoDependencia",
      },
      {
        childtitle: "Distrito de Desarrollo Rural",
        childlink: "/catalogo/distritoDeDesarrolloRural",
      },
      {
        childtitle: "Distrito de Riego",
        childlink: "/catalogo/distritoDeRiego",
      },
      {
        childtitle: "Modulo de Riego",
        childlink: "/catalogo/moduloDeRiego",
      },
      {
        childtitle: "Tipo de Siembra",
        childlink: "/catalogo/tipoSiembra",
      },
      {
        childtitle: "Roles",
        childlink: "/catalogo/roles",
      },
      {
        childtitle: "Cultivos",
        childlink: "/catalogo/cultivos",
      },
      {
        childtitle: "Ciclos Agricola",
        childlink: "/catalogo/cicloAgricola",
      },
      {
        childtitle: "Tipos de Ciclos Agricolas",
        childlink: "/catalogo/tipoCicloAgricola",
      },
      {
        childtitle: "Clasificación de Cultivos",
        childlink: "/catalogo/cultivoClasificacion",
      }
    ],
  },
  {
    title: "Permisos",
    icon: "heroicons:newspaper",
    link: "/permisos",
  },
  {
    title: "Configuración",
    icon: "heroicons-outline:cog-8-tooth",
    link: "#",
    child: [
      {
        childtitle: "Usuarios",
        childlink: "/configuracion/usuarios",
      }
    ],
  },
  // {
  //   title: "Catalógos",
  //   icon: "heroicons-outline:home",
  //   child: [
  //     {
  //       childtitle: "Analytics Dashboard",
  //       childlink: "home",
  //     },
  //     {
  //       childtitle: "Ecommerce Dashboard",
  //       childlink: "ecommerce",
  //     },
  //     {
  //       childtitle: "Project  Dashbaord",
  //       childlink: "project",
  //     },
  //     {
  //       childtitle: " CRM Dashbaord",
  //       childlink: "crm",
  //     },
  //     {
  //       childtitle: "Banking Dashboard",
  //       childlink: "banking",
  //     },
  //   ],
  // },
  // {
  //   title: "changelog",
  //   icon: "heroicons:arrow-trending-up",
  //   link: "changelog",
  //   badge: "2.0.1",
  // },
  // // {
  // //   isHeadr: true,
  // //   title: "apps",
  // // },
  // {
  //   title: "Email",
  //   icon: "heroicons-outline:mail",
  //   link: "email",
  // },

  // {
  //   title: "Kanban",
  //   icon: "heroicons-outline:view-boards",
  //   link: "kanban",
  // },
  // {
  //   title: "Calender",
  //   icon: "heroicons-outline:calendar",
  //   link: "calender",
  // },

  // {
  //   title: "Todo",
  //   icon: "heroicons-outline:clipboard-check",
  //   link: "todo",
  // },

  // {
  //   title: "Projects",
  //   icon: "heroicons-outline:document",
  //   link: "#",
  //   child: [
  //     {
  //       childtitle: "Projects",
  //       childlink: "projects",
  //     },
  //     {
  //       childtitle: "Project Details",
  //       childlink: "project-details",
  //     },
  //   ],
  // }
];