import home from '../pages/home/home';
import listTipoDependencia from '@/pages/catalogos/tipoDependencia/listTipoDependencia';
import formTipoDependencia from '@/pages/catalogos/tipoDependencia/formTipoDependencia';

const routesTipoDependencia = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'tipoDependencia',     component: listTipoDependencia },
        { path: 'tipoDependencia/add', component: formTipoDependencia },
        { path: 'tipoDependencia/:id', component: formTipoDependencia }
    ]
}]

export default{
    getRoutes(){
        return routesTipoDependencia;
    }
}