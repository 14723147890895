import home from '../pages/home/home';
import listPermisos from '@/pages/permisos/listPermisos';

const routes = [{
    path        : '/permisos',
    component   : home,
    children    :[
        { path: '',     component: listPermisos }
    ]
}]

export default{
    getRoutes(){
        return routes;
    }
}