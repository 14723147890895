import home from '../pages/home/home';
import listDependencias from '@/pages/catalogos/dependencias/listDependencias';
import formDependencias from '@/pages/catalogos/dependencias/formDependencias';

const routesDependencias = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'dependencias',     component: listDependencias },
        { path: 'dependencias/add', component: formDependencias },
        { path: 'dependencias/:id', component: formDependencias }
    ]
}]

export default{
    getRoutes(){
        return routesDependencias;
    }
}