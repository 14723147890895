import { notify } from "@kyvg/vue3-notification";

export default class Toast{
    constructor(){}

    static success(message){
        this.show('success', message)
    }

    static danger(message){
        this.show('error', message)
    }

    static show(type, message){
        notify({
            group   : 'homee',
            type    : type,
            title   : message,
        });
    }
}