import home from '../pages/home/home';
import listTipoSiembra from '@/pages/catalogos/tipoSiembra/listTipoSiembra';
import formTipoSiembra from '@/pages/catalogos/tipoSiembra/formTipoSiembra';

const routesTipoSiembra = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'tipoSiembra',     component: listTipoSiembra },
        { path: 'tipoSiembra/add', component: formTipoSiembra },
        { path: 'tipoSiembra/:id', component: formTipoSiembra }
    ]
}]

export default{
    getRoutes(){
        return routesTipoSiembra;
    }
}