import axios from "axios";

const API =  'permission';

export default{
    paginated(filters){
        return axios.post(`${API}/paginated`, filters);
    },
    PDF(id){
        return axios.get(`${API}/${id}/pdf`);
    },
}