
let prefix = process.env.NODE_ENV == 'production' ? 'sivasa-prod' : 'sivasa-dev';

export default{
    setItem(key, data){
        localStorage.setItem(`${prefix}-${key}`, data);
    },
    getItem(key){
        return localStorage.getItem(`${prefix}-${key}`);
    },
    removeItem(key){
        return localStorage.removeItem(`${prefix}-${key}`);
    }
}