import home from '../pages/home/home';
import listRoles from '@/pages/catalogos/roles/listRoles';
import formRoles from '@/pages/catalogos/roles/formRoles';

const routesRoles = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'roles',     component: listRoles },
        { path: 'roles/add', component: formRoles },
        { path: 'roles/:id', component: formRoles }
    ]
}]

export default{
    getRoutes(){
        return routesRoles;
    }
}