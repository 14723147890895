import home from '../pages/home/home';
import listDistritoDeDesarrolloRural from '@/pages/catalogos/distritoDeDesarrolloRural/listDistritoDeDesarrolloRural';
import formDistritoDeDesarrolloRural from '@/pages/catalogos/distritoDeDesarrolloRural/formDistritoDeDesarrolloRural';

const routesDistritoDeDesarrolloRural = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'distritoDeDesarrolloRural',     component: listDistritoDeDesarrolloRural },
        { path: 'distritoDeDesarrolloRural/add', component: formDistritoDeDesarrolloRural },
        { path: 'distritoDeDesarrolloRural/:id', component: formDistritoDeDesarrolloRural }
    ]
}]

export default{
    getRoutes(){
        return routesDistritoDeDesarrolloRural;
    }
}