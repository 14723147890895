<template>
    <div class="bg-white">
        <div class="card-header pb-2">
            <div class="row">
                <div class="col-lg-4">
                    <h5>Ciclo Agricola</h5>
                </div>
                <div class="col-lg-8 text-right">         
                    <router-link to="cicloAgricola/add" class="text-white">
                        <button class="btn btn-dark col-2"> Nuevo</button>
                    </router-link>
                </div>
            </div>
        </div>
        <div >
            <DataTable 
                :columnsKeys="columnsKeys" 
                :data="data" 
                :buttons="buttons" 
                :currentPage="currentPage" 
                :countData="countData" 
                v-on:change-page="changePage($event)" 
                v-on:click-button="clickButton($event)"                
                v-on:change-filters="changeFilters($event)"
                v-on:change-limit="changeLimit($event)"
            />
        </div>
    </div>

    <ModalMessage title="Ciclo Agricola" message="¿Desea eliminar el ciclo agricola?" :visible="showModalMessage" v-on:evt-close="evtDeleteModal($event)"/>
</template>
<style>
</style>
<script>
    import DataTable                from '@/components/dataTable.vue';
    import cicloAgricolaService     from '@/services/cicloAgricola.service';
    import UtilitiesService         from '@/services/utilities.service';
    import GlobalMessages           from '@/const/messages.const';
    import Toast                    from '@/class/toast.class';
    import { Icon }                 from '@iconify/vue';
    import Button                   from '@/components/plantilla/Button';
    import ModalMessage             from '@/components/ModalMessage.vue';

    export default {
        name    : 'list-cicloAgricola-page',
        data (){
            return{
                columnsKeys:[  
                    { text: 'Id',                   key: 'id',      isFilter: true },
                    { text: 'Nombre',               key: 'nombre',      isFilter: true },
                    { text: 'Estatus',              key: 'estatusId',           isSelect: true, dataSelect:[ {id: 1, text: 'ACTIVO'}, { id: 2, text: 'INACTIVO' } ] },
                ],
                data    :[],
                buttons :[
                    { id: 1, typeIcon: 'heroicons-outline:pencil',  title: 'Editar',            class:'btn btn-sm btn-primary' },
                    { id: 2, typeIcon: 'heroicons-outline:trash',   title: 'Eliminar',          class:'btn btn-sm btn-danger', 
                        evtShow: (index) => {
                            return this.data[index].estatusId == 1;
                        }
                    },
                ],
                countData   : 0,
                currentPage : 1,
                filters : { select: 'id, nombre, estatusId', where:{} },    
                showModalMessage: false,
                idSelected      : 0
            }
        },
        methods:{
            getData(){
                cicloAgricolaService.paginated(this.filters).then(res => {
                    this.data       = res.rows;
                    this.countData  = res.total;
                }).catch(err => {

                });
            },
            changePage(event){
                this.filters.currentPage = event;
                this.currentPage         = event;
                this.getData();
            },
            clickButton(itemBtn){
                switch(itemBtn.button.id){
                    case 1://EDIT
                        this.$router.push({ path: `/catalogo/cicloAgricola/${itemBtn.item.id}`});
                    break;
                    case 2://DELETE
                        this.showModalMessage   = true;
                        this.idSelected         = itemBtn.item.id;
                    break;
                }
            },
            evtDeleteModal(response){
                this.showModalMessage = false;
                if(response){
                    cicloAgricolaService.delete(this.idSelected).then(res => {
                        Toast.success('Ciclo agricola eliminado!');
                        this.getData();
                    }).catch(error => {
                        console.log(error);
                    });
                }
            },
            changeFilters(event){
                this.filters.where          = event;
                this.filters.currentPage    = 1;
                this.getData();
            },
            changeLimit(event){
                this.filters.limit = event;
                this.getData();
            } 
        },
        mounted(){
            this.getData();
        },
        components:{
            DataTable,
            Icon,
            Button,
            ModalMessage
        }
    }
</script>
