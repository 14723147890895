import home from '../pages/home/home';
import listUsuarios from '@/pages/catalogos/configuracion/usuarios/listUsuarios';
import formUsuarios from '@/pages/catalogos/configuracion/usuarios/formUsuarios';

const routesUsuario = [{
    path        : '/configuracion',
    component   : home,
    children    :[
        { path: 'usuarios',     component: listUsuarios },
        { path: 'usuarios/add', component: formUsuarios },
        { path: 'usuarios/:id', component: formUsuarios }
    ]
}]

export default{
    getRoutes(){
        return routesUsuario;
    }
}