import axios                    from 'axios';
import env                      from '@/config/env';
import utilitiesService         from '@/services/utilities.service';
import ValidateToken            from '@/config/validateToken';
import Toast                    from '@/class/toast.class';
import localStorageService      from "@/services/localStorage.service";

axios.defaults.baseURL = env.API_REST;

//CREATE INTERCEPTOR REQUEST
axios.interceptors.request.use(
    config => {       
        utilitiesService.showSpinner();
        let token = localStorageService.getItem('token');

        if(config.url !== 'authorization/login' && token){ 
            let isExpireToken = ValidateToken.validate();

            if(isExpireToken){
                Toast.danger('Expiró la sesión');
                ValidateToken.deleteTokenAndCloseSesion();
            }
            config.headers.Authorization = `Bearer ${token}`;
        }

        // let url = config.url;
        // if(url.includes('crop') || url.includes('agriculturalCycle') || url.includes('permission')){
        let tokenDependency = localStorageService.getItem('tokenDependency');
        config.headers['token-sivasa'] = tokenDependency;
        // }
        
        return config;
    },
    err => {
        utilitiesService.hideSpinner();
        return Promise.reject(err.response.data);
    }
);

axios.interceptors.response.use(
    response => {
        utilitiesService.hideSpinner();
        return response.data;
    },
    error => {
        utilitiesService.hideSpinner();

        let statusResponse = error.response.status;

        if(statusResponse == 401){
            Toast.danger('Expiró la sesión');
            ValidateToken.deleteTokenAndCloseSesion();
            error.config._retry = true;

            return axios(error.config)
        }

        return Promise.reject(error.response.data);
});

export default axios;