import VueJwtDecode             from 'vue-jwt-decode';
import moment                   from 'moment';
import localStorageService      from "@/services/localStorage.service";

export default{
    validate:function(){
        try {
            let token = localStorageService.getItem('token');

            if(!token){
                return true;
            }
            
            let decode = VueJwtDecode.decode(token);
            let iat = moment(new Date(decode.iat), 'DD-MM-YYYY HH:mm:ss');
            let exp = moment(new Date(decode.exp), 'DD-MM-YYYY HH:mm:ss');

            return iat.isAfter(exp);
        } catch (error) {
            return false;    
        }     
    },
    deleteTokenAndCloseSesion: function(){
        this.deleteLocalStorageSesion();
        setTimeout(() => {
            window.location.reload();
        }, 300)
        this.$router.push('/login');

    },
    deleteLocalStorageSesion: function(){
        localStorageService.removeItem('token');
        localStorageService.removeItem('userName');
        localStorageService.removeItem('showSpinner');
    }
}