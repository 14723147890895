<template>
    <div class="bg-white">
        <div class="card-header pb-2">
            <div class="row">
                <div class="col-lg-4">
                    <h5>Permisos</h5>
                </div>
            </div>
        </div>
        <div >
            <DataTable 
                :columnsKeys="columnsKeys" 
                :data="data" 
                :buttons="buttons" 
                :currentPage="currentPage" 
                :countData="countData" 
                v-on:change-page="changePage($event)" 
                v-on:click-button="clickButton($event)"
                v-on:change-filters="changeFilters($event)"
                v-on:change-limit="changeLimit($event)"
            />
        </div>
    </div>

    <!-- <ModalMessage title="Permisos" message="¿Desea eliminar el permiso?" :visible="showModalMessage" v-on:evt-close="evtDeleteModal($event)"/> -->
</template>
<style>
    .font-size-column{
        font-size: small;
    }
    .text-center{
        align-items: center !important;
    }
</style>
<script>
    import DataTable                from '@/components/dataTable.vue';
    import permisosService          from '@/services/permisos.service';
    import GlobalMessages           from '@/const/messages.const';
    import Toast                    from '@/class/toast.class';
    import { Icon }                 from '@iconify/vue';
    import Button                   from '@/components/plantilla/Button';
    import ModalMessage             from '@/components/ModalMessage.vue';
    import {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
    } from '@headlessui/vue';

    export default {
        name    : 'list-usuarios-page',
        data (){
            return{
                columnsKeys:[  
                    { text: 'Folio Permiso',            key: 'folioPermiso',        isFilter: true, class: 'font-size-column', classBody: 'font-size-column' },
                    { text: 'Dependencia',              key: 'nombreCorto',         isFilter: true, class: 'font-size-column', classBody: 'font-size-column' },
                    { text: 'Razón Social Productor',   key: 'razonSocialProductor',isFilter: true, class: 'font-size-column', classBody: 'font-size-column'},
                    { text: 'Cultivo',                  key: 'cultivo',             isFilter: true, class: 'font-size-column', classBody: 'font-size-column'},
                    { text: 'Superficie',               key: 'superficie',          isFilter: true, class: 'font-size-column', classBody: 'font-size-column'},
                    { text: 'Fecha Emisión',            key: 'fechaEmision',        isDate: true, class: 'font-size-column', classBody: 'font-size-column'},
                    { text: 'Estatus',                  key: 'estatusId',           isSelect: true, dataSelect:[ {id: 1, text: 'ACTIVO'}, { id: 2, text: 'INACTIVO' }], class: 'font-size-column', classBody: 'font-size-column'},
                ],
                data    :[],
                buttons :[
                    { id: 1, typeIcon: 'heroicons-outline:document-arrow-down',  title: 'Descargar PDF', class:'btn btn-sm btn-primary' },
                ],
                countData   : 0,
                currentPage : 1,
                filters : { select: 'perm.id, dep.nombreCorto, perm.folioPermiso, perm.uuid, perm.razonSocialProductor, perm.fechaEmision, permDet.cultivo, permDet.superficie, perm.estatusId', where:{}, order: 'DESC' },
                idSelected      : 0
            }
        },
        methods:{
            getData(){
                if(this.filters.where && this.filters.where.like){
                    let selects = this.filters.select.split(',');

                    this.filters.where.like.forEach(itemLike => {
                        itemLike.key = selects.find(x => x.includes(itemLike.key))
                    });
                }

                permisosService.paginated(this.filters).then(res => {
                    this.data       = res.rows;
                    this.countData  = res.total;
                }).catch(err => {

                });
            },
            changePage(event){
                this.filters.currentPage = event;
                this.currentPage         = event;
                this.getData();
            },
            clickButton(itemBtn){
                let uuid = itemBtn.item.uuid;
                switch(itemBtn.button.id){
                    case 1://DOWNLOAD
                        permisosService.PDF(uuid).then(res => {
                            this.downloadFile(res.data, `permiso-${uuid}.pdf`)
                        }).catch(err => {

                        })
                    break;
                }
            },
            changeFilters(event){
                this.filters.where          = event;
                this.filters.currentPage    = 1;
                this.getData();
            },
            changeLimit(event){
                this.filters.limit = event;
                this.getData();
            },
            downloadFile(buffer , fileName){
                const url   = window.URL.createObjectURL(new Blob([new Uint8Array(buffer).buffer]));
                const link  = document.createElement('a');
                link.href   = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
        },
        mounted(){
            this.getData();
        },
        components:{
            DataTable,
            Icon,
            Button,
            ModalMessage
        }
    }
</script>
