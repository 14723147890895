import home from '../pages/home/home';
import listTipoCicloAgricola from '@/pages/catalogos/tipoCicloAgricola/listTipoCicloAgricola';
import formTipoCicloAgricola from '@/pages/catalogos/tipoCicloAgricola/formTipoCicloAgricola';

const routesTipoCicloAgricola = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'tipoCicloAgricola',     component: listTipoCicloAgricola },
        { path: 'tipoCicloAgricola/add', component: formTipoCicloAgricola },
        { path: 'tipoCicloAgricola/:id', component: formTipoCicloAgricola }
    ]
}]

export default{
    getRoutes(){
        return routesTipoCicloAgricola;
    }
}