<template>
    <div class="bg-white">        
        <div class="card-header pb-0">
            <div class="row">
                <div class="col-lg-4">
                    <h5>{{title}}</h5>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form autocomplete="off" class="pace-y-4">
                <div class="row">                   
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Usuario</label>
                             <div class="col-sm-9">
                                <b-form-input id="nombreUsuario" class="form-control form-control-sm" v-model="item.nombreUsuario" autofocus v-on:keyup="validItemForm('nombreUsuario')" readonly="" disabled=""></b-form-input>
                            </div>
                        </div>
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Nombre Completo</label>
                             <div class="col-sm-9">
                                <b-form-input id="nombreCompleto" class="form-control form-control-sm" v-model="item.nombreCompleto" v-on:keyup="validItemForm('nombreCompleto')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Email</label>
                             <div class="col-sm-9">
                                <b-form-input id="correoElectronico" type="email" class="form-control form-control-sm" v-model="item.correoElectronico" v-on:keyup="validItemForm('correoElectronico')"></b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Rol</label>
                             <div class="col-sm-9">
                               <select id="rolId" class="form-control form-control-sm" v-model="item.rolId" v-on:change="validItemForm('rolId')">
                                    <option v-for="itemT in listRoles" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Dependencia</label>
                             <div class="col-sm-9">
                                <select id="dependenciaId" class="form-control form-control-sm" v-model="item.dependenciaId" v-on:change="validItemForm('dependenciaId')">
                                    <option v-for="itemT in listDependency" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Teléfono</label>
                             <div class="col-sm-9">
                                <b-form-input id="telefono" type="text" class="form-control form-control-sm" v-model="item.telefono" v-on:keyup="validItemForm('telefono')"></b-form-input>
                            </div>
                        </div>
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Observaciones</label>
                             <div class="col-sm-9">
                                <b-form-textarea id="observacion" type="text" class="form-control form-control-sm" rows="8" v-model="item.observacion" v-on:keyup="validItemForm('observacion')"></b-form-textarea>
                            </div>
                        </div>
                        <!-- <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Es administrador</label>
                             <div class="col-sm-9">
                                 <div class="checkbox pl-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" v-model="item.esAdmin" name="esAdmin" id="esAdmin" v-on:change="validItemForm('esAdmin')">
                                            <label class="custom-control-label" for="esAdmin"></label>
                                        </div>
                                    </div>
                            </div>
                        </div> -->
                        <!-- <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Estatus</label>
                             <div class="col-sm-9">
                                <select id="estatusId" class="form-control form-control-sm" v-model="item.estatusId" v-on:change="validItemForm('estatusId')">
                                    <option v-for="itemT in estatus" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div> -->
                    </div>
                </div>  
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-6 offset-lg-6 offset-xl-6">
                        <div class="form-group row pb-3 text-right"> 
                            <div class="col-12">
                                <router-link to="/configuracion/usuarios" class="text-white">
                                    <button class="btn btn-danger">
                                        <feather type="arrow-left" style="height: 18px; padding-top: 2px; color:white;"></feather>Regresar
                                    </button>
                                </router-link>
                                <!-- <Button text="Aceptar" btnClass="btn-dark" @click="save"/> -->
                                <button type="button" @click="save" class="ml-1 btn btn-dark"><feather type="save" style="height: 18px; padding-top: 2px;"></feather> Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>              
            </form>
        </div>
    </div>
</template>
<style>
    .password {
        text-security: disc;
        -webkit-text-security: disc;
        -moz-text-security: disc;
    }
</style>
<script>
    import admUsuariosService       from '@/services/admUsuario.service';
    import dependencyService        from "@/services/dependency.service";
    import rolService               from "@/services/rol.service";
    import _                        from 'underscore';    
    import Toast                    from '@/class/toast.class';
    import FormValidation           from '@/class/form-validation.class';
    import GlobalMessages           from '@/const/messages.const';
    import utilitiesService         from '@/services/utilities.service';
    import Button                   from '@/components/plantilla/Button';

    export default{
        name: 'form-usuarios',
        data(){
            return {
                title               : 'Nuevo Usuario',
                id                  : 0,
                item                : {},
                listDependency      : [],
                listRoles           : [],
                formValidation      : null
            }
        },
        methods:{
            async getEmpleadosTypeHead(query) {
                empleadoService.autocompleted({fullName: query}).then(res => {
                    this.empleadosTypeHead = res.data
                }).catch(error => {
                    console.log(error)
                });
            },
            getById(){
                admUsuariosService.getById(this.id).then(res => {
                    this.item = res;
                }).catch(error => {
                    console.log(error)
                });
            },
            getDataSelecteds(){
                rolService.get().then(res => {
                    this.listRoles = res;
                }).catch(error => {
                    Toast.danger(error.message);
                });

                dependencyService.get().then(res => {
                    this.listDependency = res
                }).catch(error => {
                    Toast.danger(error.message);
                });
            },
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.item[id]);
            },
            save(){
                if(!this.formValidation.isValid(this.item)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }

                admUsuariosService[this.id != 0 ? 'update' : 'create'](this.item).then(res => {                        
                    Toast.success(`Usuario ${this.id != '' ? 'actualizado' : 'creado'} exitosamente`);
                    this.$router.push('/configuracion/usuarios');
                }).catch(err => {
                    Toast.danger(this.id != 0 ? GlobalMessages.ERROR_UPDATED_DATA : GlobalMessages.ERROR_CREATE_DATA);
                });            
            }
        },
        mounted(){
            let arrayControls = [
                { id: 'nombreUsuario',      validators:{ required: false, maxLength: 20 }, default: '' },
                { id: 'nombreCompleto',     validators:{ required: true }, default: '' },
                { id: 'rolId',              validators:{ required: true }, default: 0 },
                { id: 'correoElectronico',  validators:{ required: false, maxLength: 100, email: true }, default: '' },
                { id: 'telefono',           validators:{ required: false }, default: '' },
                { id: 'observacion',        validators:{ required: false }, default: '' },
                { id: 'dependenciaId',      validators:{ required: true }, default: 0 },
            ];

            if(this.$route.params && this.$route.params.id){
                this.title  = 'Editar Usuario';
                this.id     = this.$route.params.id;
                this.getById();
            }

            this.formValidation = new FormValidation(arrayControls);
            this.item           = this.formValidation.createStructure();
            this.getDataSelecteds()
        },
        components:{
            Button
        }
    }
</script>