import home from '../pages/home/home';
import listModuloDeRiego from '@/pages/catalogos/moduloDeRiego/listModuloDeRiego';
 import formModuloDeRiego from '@/pages/catalogos/moduloDeRiego/formModuloDeRiego';

const routesModuloDeRiego = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'moduloDeRiego',     component: listModuloDeRiego },
        { path: 'moduloDeRiego/add', component: formModuloDeRiego },
        { path: 'moduloDeRiego/:id', component: formModuloDeRiego }
    ]
}]

export default{
    getRoutes(){
        return routesModuloDeRiego;
    }
}