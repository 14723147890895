<template>
    <div class="table-responsive">
        <table class="table table-sm table-hover">
            <thead>
                <tr>
                    <th v-for="column in columnsKeys" v-bind:class="hasFilter(column)" :class="column.class">
                        {{column.text}}
                        <input class="form-control form-control-sm" v-if="column.isFilter" v-bind:placeholder="'Buscar por ' + column.text" v-model="filters[column.filterKey ? column.filterKey : column.key]" v-on:keyup.enter="changeFilter" v-on:keyup="keyUp"/>
                        <select v-if="column.isSelect" class="form-control form-control-sm" v-model="filters[column.filterKey ? column.filterKey : column.key]" @change="selectedValue">
                            <option v-for="option in column.dataSelect" v-bind:value="option.id">{{option.text}}</option>
                        </select>
                        <input type="date" v-if="column.isDate" class="form-control form-control-sm" v-model="filters[column.filterKey ? column.filterKey : column.key]">
                    </th>
                    <th v-bind:class="hasFilter()" class="col-2">Opciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in data">
                    <td v-for="column in columnsKeys" :class="column.classBody">
                        <template v-if="column.key != 'estatusId' && !column.isDate">{{ column.subKey && item[column.key] ? item[column.key][column.subKey] : item[column.key]}}</template>
                        <template v-if="column.key != 'estatusId' && column.isDate">{{ column.subKey && item[column.key] ? formatDate(item[column.key][column.subKey]) : formatDate(item[column.key])}}</template>
                        <template v-if="column.key == 'estatusId' && !column.subKey">{{item[column.key] == 1 ? 'ACTIVO' : 'INACTIVO'}}</template>
                        <template v-if="column.key == 'estatusId' && column.subKey">{{item[column.key][column.subKey]}}</template>
                    </td>
                    <td>
                        <template v-for="itemButton in buttons">
                            <template v-if="!itemButton.evtShow">
                                <button v-bind:class="itemButton.class"
                                    class="mr-1"
                                    style="padding: 0.25rem 0.5rem; font-size: 0.875rem;"
                                    v-bind:title="itemButton.title"
                                    @click="clickButton(itemButton, item)">
                                    <Icon :icon="itemButton.typeIcon" />
                                </button>
                            </template>
                            <template v-if="itemButton.evtShow && itemButton.evtShow(index)">
                                <button v-bind:class="itemButton.class"
                                    class="mr-1"
                                    style="padding: 0.25rem 0.5rem; font-size: 0.875rem;"
                                    v-bind:title="itemButton.title"
                                    @click="clickButton(itemButton, item)">
                                    <Icon :icon="itemButton.typeIcon" />
                                </button>
                            </template>
                        </template>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td v-bind:colspan="columnsKeys.length">
                        <b-pagination v-model="currentPageD" :total-rows="countData" :per-page="totalForPage" aria-controls="my-table"></b-pagination>  
                    </td>
                    <td>
                        <select class="form-control form-control-sm" v-model="totalForPage" @change="selectedForPage">
                            <option v-for="option in dataForPage" v-bind:value="option">{{option}}</option>
                        </select>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>
<style>
    thead > tr > th {
        border-top:0px !important;
    }

    .header-top{
        vertical-align: top !important;
    }
</style>
<script>
import { Icon } from '@iconify/vue';
import moment from 'moment';

export default {
    name : 'data-table-component',
    props: ['columnsKeys', 'data', 'buttons', 'countData', 'currentPage'],
    data(){
        return{
            listColumns : [],
            filters     : {},
            intervalFn  : null,
            dataForPage :[ 10, 20, 30, 50, 100 ],
            totalForPage:10
        }
    },
    computed:{
        currentPageD:{
            get: function(){
                return this.currentPage
            },
            set: function(value){
                this.$emit('change-page', value)
            }
        }
    },
    methods:{
        clickButton: function(itemBtn, item){
            this.$emit('click-button', {item: item, button: itemBtn})
        },
        hasFilter: function(column = null){
            let _hasFilter = this.columnsKeys.filter(x => x.isFilter).length > 0;

            return _hasFilter && (column == null || !column.isFilter)  ? 'header-top' : '';
        },
        changeFilter(){
            this.$emit('change-filters', this.toWhereAPI())
        },
        keyUp(){
            if(this.intervalFn){
                clearTimeout(this.intervalFn);
            }

            this.intervalFn = setTimeout(() => {
                this.$emit('change-filters', this.toWhereAPI());
            },500);
        },
        toWhereAPI(){
            let objWhere = { like: [], and: [], or: [] }

            for(let key in this.filters){
                let valueObj = this.filters[key];

                if(valueObj != ''){
                    if(this.isFilterText(key))
                        objWhere.like.push({ key, value: valueObj });
                    if(this.isFilterSelect(key))
                        objWhere.and.push({ key, value: valueObj });
                }
            }

            return objWhere;
        },
        isFilterText(key){
            let findColumn = this.columnsKeys.find(x => x.key == key) || null;

            if(!findColumn){
                return false;
            }

            return findColumn.isFilter;
        },
        isFilterSelect(key){
            let findColumn = this.columnsKeys.find(x => x.key == key) || null;

            if(!findColumn){
                return false;
            }

            return findColumn.isSelect;
        },
        selectedForPage(){
            this.$emit('change-limit', this.totalForPage);
        },
        selectedValue(){
            this.$emit('change-filters', this.toWhereAPI())
        },
        formatDate(value){
         if (value) {
           return moment(String(value)).format('DD/MM/YYYY')
          }
        },
    },
    mounted(){
        this.columnsKeys.forEach(x => {
            this.filters[x.key] = '';
        });

        this.totalForPage = this.dataForPage[0];
    },
    components: {
        Icon,
    }
}
</script>
