import home from '../pages/home/home';
import listCicloAgricola from '@/pages/catalogos/cicloAgricola/listCicloAgricola';
import formCicloAgricola from '@/pages/catalogos/cicloAgricola/formCicloAgricola';

const routesCicloAgricola = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'cicloAgricola',     component: listCicloAgricola },
        { path: 'cicloAgricola/add', component: formCicloAgricola },
        { path: 'cicloAgricola/:id', component: formCicloAgricola }
    ]
}]

export default{
    getRoutes(){
        return routesCicloAgricola;
    }
}