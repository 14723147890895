<template>
    <div>     
      <router-view></router-view>
      <notifications group="homee" />
    </div>

</template>

<script>
  import { useThemeSettingsStore } from "@/plantilla/store/themeSettings"; 

  export default {
    name: 'App',
    mounted() {
      this.$store.themeSettingsStore = useThemeSettingsStore()
    }
  }
</script>

<style lang="scss">
  /*@import "~@/assets/scss/vendors/bootstrap-vue/index";*/
</style>
