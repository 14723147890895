<template>
    <div class="bg-white">        
        <div class="card-header pb-0">
            <div class="row">
                <div class="col-lg-4">
                    <h5>{{title}}</h5>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form autocomplete="off" class="pace-y-4">
                <div class="row">                   
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Nombre</label>
                             <div class="col-sm-9">
                                <b-form-input id="nombre" class="form-control form-control-sm" v-model="item.nombre" v-on:keydown="validItemForm('nombre')" v-on:keyup="validItemForm('nombre')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Número</label>
                             <div class="col-sm-9">
                                <b-form-input id="numero" class="form-control form-control-sm" v-model="item.numero" v-on:keydown="validItemForm('numero')" v-on:keyup="validItemForm('numero')"></b-form-input>
                            </div>
                        </div>                        

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Calle</label>
                             <div class="col-sm-9">
                                <b-form-input id="calle" class="form-control form-control-sm" v-model="item.calle" v-on:keydown="validItemForm('calle')" v-on:keyup="validItemForm('calle')"></b-form-input>
                            </div>
                        </div>                       

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Número Exterior</label>
                             <div class="col-sm-9">
                                <b-form-input id="numeroExterior" class="form-control form-control-sm" v-model="item.numeroExterior" v-on:keydown="validItemForm('numeroExterior')" v-on:keyup="validItemForm('numeroExterior')"></b-form-input>
                            </div>
                        </div>                        

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Código Postal</label>
                             <div class="col-sm-9">
                                <b-form-input id="codigoPostal" class="form-control form-control-sm" v-model="item.codigoPostal" v-on:keydown="validItemForm('codigoPostal')" v-on:keyup="validItemForm('codigoPostal')"></b-form-input>
                            </div>
                        </div>                        

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Teléfono</label>
                             <div class="col-sm-9">
                                <b-form-input id="telefono" class="form-control form-control-sm" v-model="item.telefono" v-on:keydown="validItemForm('telefono')" v-on:keyup="validItemForm('telefono')"></b-form-input>
                            </div>
                        </div>                        

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Estado</label>
                             <div class="col-sm-9">
                                <select id="estadoId" class="form-control form-control-sm" v-model="item.estadoId" v-on:change="validItemForm('estadoId')">
                                    <option v-for="itemT in estados" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>                       

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Superficie Total</label>
                             <div class="col-sm-9">
                                <b-form-input id="superficieTotal" class="form-control form-control-sm" v-model="item.superficieTotal" v-on:keydown="validItemForm('superficieTotal')" v-on:keyup="validItemForm('superficieTotal')"></b-form-input>
                            </div>
                        </div>                       

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Superficie Particular</label>
                             <div class="col-sm-9">
                                <b-form-input id="superficieParticular" class="form-control form-control-sm" v-model="item.superficieParticular" v-on:keydown="validItemForm('superficieParticular')" v-on:keyup="validItemForm('superficieParticular')"></b-form-input>
                            </div>
                        </div>                                            
                        
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Descripción</label>
                             <div class="col-sm-9">
                                <b-form-input id="descripcion" class="form-control form-control-sm" v-model="item.descripcion" v-on:keydown="validItemForm('descripcion')" v-on:keyup="validItemForm('descripcion')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Cabecera</label>
                             <div class="col-sm-9">
                                <b-form-input id="cabecera" class="form-control form-control-sm" v-model="item.cabecera" v-on:keydown="validItemForm('cabecera')" v-on:keyup="validItemForm('cabecera')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Número Interior</label>
                             <div class="col-sm-9">
                                <b-form-input id="numeroInterior" class="form-control form-control-sm" v-model="item.numeroInterior" v-on:keydown="validItemForm('numeroInterior')" v-on:keyup="validItemForm('numeroInterior')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Colonia</label>
                             <div class="col-sm-9">
                                <b-form-input id="colonia" class="form-control form-control-sm" v-model="item.colonia" v-on:keydown="validItemForm('colonia')" v-on:keyup="validItemForm('colonia')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Jefe de Distrito</label>
                             <div class="col-sm-9">
                                <b-form-input id="jefeDeDistrito" class="form-control form-control-sm" v-model="item.jefeDeDistrito" v-on:keydown="validItemForm('jefeDeDistrito')" v-on:keyup="validItemForm('jefeDeDistrito')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Correo</label>
                             <div class="col-sm-9">
                                <b-form-input id="correoElectronico" class="form-control form-control-sm" v-model="item.correoElectronico" v-on:keydown="validItemForm('correoElectronico')" v-on:keyup="validItemForm('correoElectronico')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Municipio</label>
                             <div class="col-sm-9">
                                <select id="municipioId" class="form-control form-control-sm" v-model="item.municipioId" v-on:change="validItemForm('municipioId')">
                                    <option v-for="itemT in municipios" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Superficie Ejidal</label>
                             <div class="col-sm-9">
                                <b-form-input id="superficieEjidal" class="form-control form-control-sm" v-model="item.superficieEjidal" v-on:keydown="validItemForm('superficieEjidal')" v-on:keyup="validItemForm('superficieEjidal')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Estatus</label>
                             <div class="col-sm-9">
                                <select id="estatusId" class="form-control form-control-sm" v-model="item.estatusId" v-on:change="validItemForm('estatusId')">
                                    <option v-for="itemT in estatus" v-bind:value="itemT.id" >{{itemT.text}}</option>
                                </select>
                            </div>
                        </div>  

                    </div>
                </div>  
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-6 offset-lg-6 offset-xl-6">
                        <div class="form-group row pb-3 text-right"> 
                            <div class="col-12">
                                <router-link to="/catalogo/distritoDeDesarrolloRural" class="text-white">
                                    <button class="btn btn-danger">
                                        <feather type="arrow-left" style="height: 18px; padding-top: 2px; color:white;"></feather>Regresar
                                    </button>
                                </router-link>
                                <!-- <Button text="Aceptar" btnClass="btn-dark" @click="save"/> -->
                                <button type="button" @click="save" class="ml-1 btn btn-dark"><feather type="save" style="height: 18px; padding-top: 2px;"></feather> Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>              
            </form>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import distritoDeDesarrolloRuralService     from "@/services/distritoDeDesarrolloRural.service";
    import municipioService                     from "@/services/municipio.service";
    import estadoService                        from "@/services/estado.service";
    import _                                    from 'underscore';    
    import Toast                                from '@/class/toast.class';
    import FormValidation                       from '@/class/form-validation.class';
    import GlobalMessages                       from '@/const/messages.const';
    import utilitiesService                     from '@/services/utilities.service';
    import Button                               from '@/components/plantilla/Button';

    export default{
        name: 'form-distritoDeDesarrolloRural',
        data(){
            return {
                title               : 'Nuevo Distrito de Desarrollo Rural',
                id                  : 0,
                item                : {},
                estatus             : [ {id: 1, text: 'ACTIVO'}, { id: 2, text: 'INACTIVO' } ],
                municipios          : [],
                estados             : [],
                formValidation      : null
            }
        },
        methods:{           
            getById(){
                distritoDeDesarrolloRuralService.getById(this.id).then(res => {
                    this.item = res;
                }).catch(error => {
                    console.log(error)
                });
            },         
            getDataSelecteds(){
                municipioService.get().then(res => {
                    this.municipios = res;
                }).catch(error => {
                    Toast.danger(error.message);
                });

                estadoService.get().then(res => {
                    this.estados = res
                }).catch(error => {
                    Toast.danger(error.message);
                });
            },
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.item[id]);
            },
            save(){
                if(!this.formValidation.isValid(this.item)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }

                distritoDeDesarrolloRuralService[this.id != 0 ? 'update' : 'create'](this.item).then(res => {                        
                    Toast.success(`Distrito ${this.id != '' ? 'actualizado' : 'creado'} exitosamente`);
                    this.$router.push('/catalogo/distritoDeDesarrolloRural');
                }).catch(err => {
                    Toast.danger(this.id != 0 ? GlobalMessages.ERROR_UPDATED_DATA : GlobalMessages.ERROR_CREATE_DATA);
                });            
            }
        },
        mounted(){
            let arrayControls = [
                { id: 'nombre',                 validators:{ required: true, maxLength: 45 }, default: '' },
                { id: 'descripcion',            validators:{ required: false, maxLength: 300 }, default: '' },
                { id: 'numero',                 validators:{ required: true, maxLength: 3 }, default: '' },                
                { id: 'cabecera',               validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'calle',                  validators:{ required: false, maxLength: 200 }, default: '' },
                { id: 'numeroExterior',         validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'numeroInterior',         validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'colonia',                validators:{ required: false, maxLength: 100 }, default: '' },
                { id: 'codigoPostal',           validators:{ required: false, maxLength: 5 }, default: '' },
                { id: 'jefeDeDistrito',         validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'telefono',               validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'correoElectronico',      validators:{ required: false, maxLength: 50 }, default: '' },
                { id: 'estadoId',               validators:{ required: true }, default: 0 },
                { id: 'municipioId',            validators:{ required: true }, default: 0 },                
                { id: 'superficieTotal',        validators:{ required: false, maxLength: 50 }, default: 0 },
                { id: 'superficieEjidal',       validators:{ required: false, maxLength: 50 }, default: 0 },
                { id: 'superficieParticular',   validators:{ required: false, maxLength: 50 }, default: 0 },
                { id: 'estatusId',              validators:{ required: true }, default: 1 },
            ];

            if(this.$route.params && this.$route.params.id){
                this.title  = 'Editar Distrito';
                this.id     = this.$route.params.id;
                this.getById();
            }

            this.formValidation = new FormValidation(arrayControls);
            this.item           = this.formValidation.createStructure();
            this.getDataSelecteds();
        },
        components:{
            Button
        }
    }
</script>