<template>
  <div class="main-menu">
    <DesktopMenu />
  </div>
</template>
<script>
import DesktopMenu from "./Navtools/DesktopMenu";
export default {
  components: {
    DesktopMenu,
  },
};
</script>
<style lang=""></style>
