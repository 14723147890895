import home from '../pages/home/home';
import listCultivoClasificacion from '@/pages/catalogos/cultivoClasificacion/listCultivoClasificacion';
import formCultivoClasificacion from '@/pages/catalogos/cultivoClasificacion/formCultivoClasificacion';

const routesCultivoClasificacion = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'cultivoClasificacion',     component: listCultivoClasificacion },
        { path: 'cultivoClasificacion/add', component: formCultivoClasificacion },
        { path: 'cultivoClasificacion/:id', component: formCultivoClasificacion }
    ]
}]

export default{
    getRoutes(){
        return routesCultivoClasificacion;
    }
}