<template>
    <div class="loginwrapper">
    <div class="lg-inner-column">
      <div class="left-column relative z-[1]">
        <div
          class="absolute left-0 2xl:bottom-[-160px] bottom-[-130px] h-full w-full z-[-1]"
        >
          <img
            :src="sideImg"
            alt=""
            class="h-full w-full object-contain"
          />
        </div>
      </div>
      <div class="right-column relative">
        <div
          class="inner-content h-full flex flex-col bg-white dark:bg-slate-800"
        >
          <div class="auth-box h-full flex flex-col justify-center">
            <div class="mobile-logo text-center mb-6 lg:hidden block">
              <router-link to="/">
                <img
                  :src="logoWhite"
                  alt=""
                  class="mx-auto"
                />
              </router-link>
            </div>
            <div class="text-center 2xl:mb-10 mb-4">
              <h4 class="font-medium">Sign in</h4>
              <div class="text-slate-500 text-base">
                Sign in to your account to start using Dashcode
              </div>
            </div>
            <form class="space-y-4" autocomplete="off">
                <Textinput
                    autofocus
                    id="user"
                    label="Usuario"
                    type="text"
                    name="user"
                    placeholder="Ingrese usuario"
                    v-model="loginP.user"
                    classInput="h-[48px]"
                    v-on:keyup="validItemForm('user')"
                    v-on:keyup.enter="login"
                ></Textinput>
                <Textinput
                    id="password"
                    label="Contraseña"
                    type="password"
                    name="password"
                    placeholder="Ingrese contraseña"
                    v-model="loginP.password"
                    classInput="h-[48px]"
                    hasicon
                    v-on:keyup="validItemForm('password')" v-on:keyup.enter="login"
                ></Textinput>

                <button type="button" class="btn btn-dark block w-full text-center" @click="login()">Sign in</button>
            </form>
            <div class="relative border-b-[#9AA2AF] border-opacity-[16%] border-b pt-6"></div>
            <div class="md:max-w-[345px] mx-auto font-normal text-slate-500 dark:text-slate-400 mt-12 uppercase text-sm">
              Don’t have an account? Sign up
              <router-link to="/register" class="text-slate-900 dark:text-white font-medium hover:underline"> Sign up</router-link>
            </div>
          </div>
          <div class="auth-footer text-center">
            Copyright 2021, Dashcode All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import authenticationServices   from "@/services/authentication.services";
    import localStorageService      from "@/services/localStorage.service";
    import Toast                    from '@/class/toast.class';
    import FormValidation           from '@/class/form-validation.class';
    import GlobalMessages           from '@/const/messages.const';
    import Textinput                from "@/components/plantilla/Textinput";

    export default {
        name    : 'login-page',
        data(){
            return{
                init            : true,
                isInit          : false,
                isSignIn        : false,
                loginP          : {},
                formValidation  : new FormValidation([
                    { id: 'user',    validators:{ required: true }},
                    { id: 'password',validators:{ required: true }},
                ]), 
                logo : "images/auth/ils1.svg",
                logoWhite: "images/logo/logo-white.svg",
                sideImg : "images/auth/ils1.svg"
            }
        },
        methods:{
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.loginP[id]);
            },
            login(){
                if(!this.formValidation.isValid(this.loginP)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }

                authenticationServices.login(this.loginP).then(res => {
                  localStorageService.setItem('token',   res.token)
                  localStorageService.setItem('userName',res.userName);
                  localStorageService.setItem('tokenDependency',res.tokenDependency);
                  Toast.success('Bienvenido!');

                  this.$router.push('/home');
                }).catch(error => {
                  console.log('errpr', error)
                    Toast.danger(error.message);
                });
            }
        },
        components: {
            Textinput
        },
        mounted(){
            this.loginP = this.formValidation.createStructure();
        }
    }
</script>