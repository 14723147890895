import home from '../pages/home/home';
import listDistritoDeRiego from '@/pages/catalogos/distritoDeRiego/listDistritoDeRiego';
import formDistritoDeRiego from '@/pages/catalogos/distritoDeRiego/formDistritoDeRiego';

const routesDistritoDeRiego = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'distritoDeRiego',     component: listDistritoDeRiego },
        { path: 'distritoDeRiego/add', component: formDistritoDeRiego },
        { path: 'distritoDeRiego/:id', component: formDistritoDeRiego }
    ]
}]

export default{
    getRoutes(){
        return routesDistritoDeRiego;
    }
}