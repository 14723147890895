import home from '../pages/home/home';
import listCultivos from '@/pages/catalogos/cultivos/listCultivos';
import formCultivos from '@/pages/catalogos/cultivos/formCultivos';

const routesCultivos = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'cultivos',     component: listCultivos },
        { path: 'cultivos/add', component: formCultivos },
        { path: 'cultivos/:id', component: formCultivos }
    ]
}]

export default{
    getRoutes(){
        return routesCultivos;
    }
}