import axios from "axios";

const API =  'plantingType';

export default{
    get(params){
        return axios.get(API,{ params: params});
    },
    paginated(filters){
        return axios.post(`${API}/paginated`, filters);
    },
    getById(id) {
        return axios.get(`${API}/${id}`);
    },
    create(data) {
        return axios.post(`${API}`, data);
    },
    update(data) {
        return axios.put(`${API}/${data.id}`, data);
    },
    delete(id) {
        return axios.delete(`${API}/${id}`);
    },
}