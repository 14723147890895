import NProgress from 'nprogress';
import localStorageService      from "@/services/localStorage.service";

export default{
    showModalConfirm(thes, title, message, txtOk = 'Sí', txtCancel = 'Cancelar'){
        return new Promise((resolve, reject) => {
            thes.$bvModal.msgBoxConfirm(message, {
                title           : title,
                // size: 'sm',
                buttonSize      : 'sm',
                okVariant       : 'success',
                cancelVariant   : 'danger',
                okTitle         : txtOk,
                cancelTitle     : txtCancel,
                // headerClass: 'p-2 border-bottom-0',
                footerClass     : 'border-top-0',
                centered        : true
                
            }).then(res => {
                res ? resolve() : reject()
            }).catch(error => { console.log(error)});
        });
    },
    showSpinner(){
        if(localStorageService.getItem('showSpinner') != ''){
            NProgress.start();
            $("#bg-spinner").css("display", "block");
        }        
    },
    removeLSTSpinner(){
        localStorageService.setItem('showSpinner','');
    },
    hideSpinner(){
        NProgress.done();
        localStorageService.setItem('showSpinner','1');
        $("#bg-spinner").css("display", "none");
    },
    classesToString(classes){
        let stringClass = '';
        Object.keys(classes).map(function(key) {
            if(classes[key]){
                stringClass += ' ' + key;
            }
        });                
        return stringClass;
    }
}