//import Vue from 'vue'
import { createWebHistory, createRouter } from "vue-router"; //Router
import ValidateToken from '@/config/validateToken';

import login from '../pages/login';
import page404 from '../pages/404';
import home from '../pages/home/home';
/*import listClientes from '@/pages/catalogos/clientes/listClientes';
import formClientes from '@/pages/catalogos/clientes/formClientes';*/

import routesUsers from '@/routes/usuario.routes';
import routesRoles from '@/routes/rol.routes';
import routesDependencias from '@/routes/dependencia.routes';
import routesDistritoDeDesarrolloRural from '@/routes/distritoDeDesarrolloRural.routes';
import routesCicloAgricola from '@/routes/cicloAgricola.routes';
import routesCultivo from '@/routes/cultivo.routes';
import routesPermisos from '@/routes/permisos.routes';
import routesTipoCicloAgricola from '@/routes/tipoCicloAgricola.routes';
import routesCultivoClasificacion from '@/routes/cultivoClasificacion.routes';
import routesTipoDependencia from '@/routes/tipoDependencia.routes';
import routesTipoSiembra from '@/routes/tipoSiembra.routes';
import routesDistritoDeRiego from '@/routes/distritoDeRiego.routes';
import routesModuloDeRiego from '@/routes/moduloDeRiego.routes';

/*import routesUsuario from '@/routes/usuario';*/

//Vue.use(Router)

var routes = [
    { path: '',         component: login },
    { path: '/login',   component: login },
    { 
        path        : '/home',
        component   : home
    },
];

routes = routes.concat(routesUsers.getRoutes());
routes = routes.concat(routesRoles.getRoutes());
routes = routes.concat(routesDependencias.getRoutes());
routes = routes.concat(routesDistritoDeDesarrolloRural.getRoutes());
routes = routes.concat(routesCicloAgricola.getRoutes());
routes = routes.concat(routesCultivo.getRoutes());
routes = routes.concat(routesPermisos.getRoutes());
routes = routes.concat(routesTipoCicloAgricola.getRoutes());
routes = routes.concat(routesCultivoClasificacion.getRoutes());
routes = routes.concat(routesTipoDependencia.getRoutes());
routes = routes.concat(routesTipoSiembra.getRoutes());
routes = routes.concat(routesDistritoDeRiego.getRoutes());
routes = routes.concat(routesModuloDeRiego.getRoutes());

//agregamos la ruta comodin
routes.push({ path: '/:pathMatch(.*)*', name: 'not-found', component: page404 });

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
}); 

router.beforeEach((to, from, next) => {
    try {
        let expireToken = ValidateToken.validate();

        if(to.path !== '/login' && expireToken){
            next({ path: '/login' });
        }else{
            next();
        }   
    } catch (error) {
        next({ path: '/login' });
    }
});
  
// router.afterEach((to, from) => {
//     console.log(to + from)
// });

export default router